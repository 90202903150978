import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: (props: any) => ({
      boxShadow: 'none',
      height: props.appBarHeight || '76px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    }),
    container: (props: any) => ({
      top: props.offsetHeight || '56px',
      zIndex: 1200,
      position: 'absolute',
      background: props.background || '#FAFAFA',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
      overflowY: 'scroll',
      height: `calc(100% - ${props.offsetHeight || '56px'})`,
      minHeight: '420px',
      paddingTop: '20px',
      paddingBottom: '20px',
      width: props.width || '100%',
      marginLeft: props.marginLeft || 0,
    }),
    backButton: {
      paddingLeft: '20px',
      paddingRight: '6px',
      border: 'none',
      transform: 'scale(0.9)',
      backgroundColor: 'transparent',
      '& img': {
        width: '12px',
        marginRight: theme.spacing(1.5),
      },
    },
    footer: {
      position: 'absolute',
      zIndex: 1300,
      bottom: '0px',
      left: '0px',
      right: '0px',
    },
    mtuBrand: {
      width: '100%',
      margin: theme.spacing(0),
    },
    list: {
      paddingTop: '24px',
      paddingBottom: '13px',

      '& li > a': {
        color: '#153947',
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 500,
        padding: '9px 24px',
      },
    },
    fullList: {
      width: 'auto',
    },
    drawerButton: props => ({
      color: '#ffffff',
      padding: '12px',
      ...props.appDrawerButton,
    }),
    logo: {
      width: '5em',
      height: '1.6111111111111112em',
    },
    blankAction: {
      display: 'inline-flex',
      width: '48px',
    },
    identifyLogo: {
      cursor: 'pointer',
      display: 'inline-flex',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    title: {
      fontFamily: 'Gotham Medium',
      fontSize: '20px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    rightActionTool: props => ({
      margin: '16px 20px 0 0',
      backgroundSize: '100%',
      ...props?.rightActionTool,
    }),
    '@media (min-width: 429px)': {
      drawer: {
        '& div:nth-child(3)': {
          width: '384px',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          padding: '0px 16px',
        },
      },
      cross: {
        display: 'none',
      },
    },
    '@media (max-width: 428px)': {
      drawer: {
        '& div:nth-child(3)': {
          width: 'calc(100% - 56px)',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          padding: '0px 16px',
        },
      },
    },
    drawer: {},
    cross: {
      position: 'fixed',
      top: '50%',
      right: '12px',
      transform: 'translate(-50%, -50%)',
      zIndex: 9999,
    },
    welcomeContainer: {
      display: 'flex',
      margin: '49px 0px 26px',
    },
    welcomeImage: {
      margin: '5px 16px 0px 13px',
    },
    navTitle: {
      fontFamily: 'Gotham Medium',
      fontSize: '18px',
      fontWeight: 500,
      color: '#636363',
    },
    navSubtitle: {
      fontSize: '16px',
    },
    feedbackContainer: {
      position: 'absolute',
      bottom: '56px',
      left: '39px',
    },
    feedbackButton: {
      width: '100%',
      display: 'flex',
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      outline: 'inherit',

      '&:focus': {
        outline: 'none',
      },
    },
    feedbackText: {
      fontSize: '20px',
    },
    feedbackSVG: {
      margin: '5px 22px 0px 0px',
    },
  }),
);

export default useStyles;
